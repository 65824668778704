var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "DateRangePicker"
  }, [_vm.title ? _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('div', {
    staticClass: "daterange",
    on: {
      "click": _vm.onShow
    }
  }, [_c('van-icon', {
    attrs: {
      "name": "notes-o"
    }
  }), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.startDate || '开始日期'))]), _c('div', {
    staticClass: "tip"
  }, [_vm._v("至")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.endDate || '结束日期'))]), _c('van-icon', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "name": "cross",
      "size": "16px",
      "color": "#ccc"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClear.apply(null, arguments);
      }
    }
  })], 1)]), _c('van-popup', {
    staticStyle: {
      "height": "60vh"
    },
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('div', {
    staticClass: "van-picker__toolbar"
  }, [_c('button', {
    staticClass: "van-picker__cancel",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v("取消")]), _c('div', {
    staticClass: "van-ellipsis van-picker__title"
  }), _c('button', {
    staticClass: "van-picker__confirm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_vm._v("确认")])]), _c('van-calendar', {
    ref: "calendar",
    attrs: {
      "poppable": false,
      "show-title": false,
      "type": "range",
      "allow-same-day": true,
      "default-date": null,
      "min-date": _vm.minDate,
      "max-date": _vm.maxDate,
      "show-confirm": false
    },
    on: {
      "confirm": _vm.onResult
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };