import moment from 'moment';
export default {
  name: 'DateRangePicker',
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: null
    }
  },
  computed: {
    startDate() {
      if (this.value && this.value[0]) {
        return moment(this.value[0]).format('YYYY-MM-DD');
      } else {
        return null;
      }
    },
    endDate() {
      if (this.value && this.value[1]) {
        return moment(this.value[1]).format('YYYY-MM-DD');
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      show: false,
      minDate: moment().add(-5, 'year').startOf('year').toDate(),
      maxDate: moment().add(5, 'year').endOf('year').toDate(),
      tempValue: null
    };
  },
  methods: {
    onShow() {
      this.show = true;
      this.$nextTick(() => {
        if (this.$refs.calendar) {
          console.log(this.value);
          this.$refs.calendar.reset(this.value);
          if (this.value && this.value[0]) {
            this.$refs.calendar.scrollToDate(this.value[0]);
          } else {
            this.$refs.calendar.scrollToDate(new Date());
          }
        }
      });
    },
    onCancel() {
      this.show = false;
    },
    onResult(e) {
      console.log(e);
      if (e && e.length) {
        this.tempValue = [e[0], moment(e[1]).endOf('day').toDate()];
      } else {
        this.tempValue = e;
      }
    },
    onClear() {
      this.tempValue = null;
      this.$emit('input', null);
      this.$emit('change', null);
    },
    onConfirm() {
      this.$emit('input', this.tempValue);
      this.$emit('change', this.tempValue);
      this.show = false;
    }
  }
};